<template>
  <div
    class="border-white flex-none rounded-full bg-gray-50 peer flex justify-center"
    :class="{
      'cursor-pointer': isEditable,
    }"
    aria-label="Profile photo"
  >
    <img
      v-if="!provider.isCompany && !provider.profileImage"
      class="rounded-full bg-gray-50 w-full h-full"
      src="~/assets/icons/avatar.svg"
      alt="avatar"
    />
    <div
      v-if="provider.isCompany && !provider.profileImage"
      class="rounded-full bg-gray-50 text-gray-400 w-full h-full"
      alt="avatar"
    >
      <BuildingOffice2Icon
        :class="{
          'm-6': size === 'large',
          'm-2': size === 'small',
          'm-3': size === 'medium',
        }"
      />
    </div>
    <NuxtImg
      v-if="provider.profileImage"
      :src="provider.profileImage"
      class="rounded-full w-full h-full"
      provider="imagekit"
      width="100"
      height="100"
    />
    <PlusCircleIcon
      v-if="isEditable"
      class="absolute bottom-0 right-2 text-blue-400 w-8 h-8 border-2 rounded-full border-white bg-white"
    />
  </div>
</template>

<script setup>
import { PlusCircleIcon } from '@heroicons/vue/24/solid';
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline';
const props = defineProps(['provider', 'isEditable', 'size']);
const size = ref(props.size || 'medium');
</script>
